import { IDestroyable } from '@entities/shared/destroyable.interface';
import { Observable, Subject, Subscription } from 'rxjs';
export enum LoadingState {
  InProgress,
  Completed,
  Error,
}
export class ObservableState implements IDestroyable {
  private _stateChange = new Subject<LoadingState>();
  public stateChange = this._stateChange.asObservable();
  private subscription: Subscription;
  public constructor(private observable: Observable<any>) {}

  public watch(): void {
    this._stateChange.next(LoadingState.InProgress);
    this.subscription = this.observable.subscribe({
      error: () => {
        this._stateChange.next(LoadingState.Error);
      },
      complete: () => {
        this._stateChange.next(LoadingState.Completed);
      },
    });
  }

  public onDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
