import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionManager } from '@core/utils/subscription-manager';
import { Observable, of } from 'rxjs';
import { LoadingState, ObservableState } from './observable.state';

@Injectable({
  providedIn: 'root',
})
export class PageStateService implements OnDestroy {
  private initializationState: ObservableState;
  private savingState: ObservableState;
  private subscriptionManager = new SubscriptionManager();

  private _isCreating: boolean;
  public get isCreating(): boolean {
    return this._isCreating;
  }
  private _isEditing: boolean;
  public get isEditing(): boolean {
    return this._isEditing;
  }

  private _isInitializing: boolean = true;
  public get isInitializing() {
    return this._isInitializing;
  }
  private _isInitialized: boolean;
  public get isInitialized() {
    return this._isInitialized;
  }
  private _hasErrorInitializing: boolean;
  public get hasErrorInitializing() {
    return this._hasErrorInitializing;
  }
  private _isSaving: boolean;
  public get isSaving() {
    return this._isSaving;
  }
  private _isSaved: boolean;
  public get isSaved() {
    return this._isSaved;
  }
  private _hasErrorSaving: boolean;
  public get hasErrorSaving() {
    return this._hasErrorSaving;
  }

  public constructor(private router: Router) {}

  public ngOnDestroy(): void {
    this.subscriptionManager.onDestroy();
    this.initializationState?.onDestroy();
    this.savingState?.onDestroy();
  }
  /**
   * Determins if the last path segment is 'new' which
   * indicates we are creating. Returns true if we
   * are creating, false if we're editing.
   *
   * @returns boolean
   */
  public determineCreateOrEdit(): Observable<boolean> {
    const url = this.router.url.split('/');
    if (url[url.length - 1] === 'new') {
      this._isCreating = true;
      this._isEditing = false;
    } else {
      this._isCreating = false;
      this._isEditing = true;
    }
    return of(this._isCreating);
  }

  public setInitialized(isInitialized: boolean): void {
    this._isInitialized = isInitialized;
    if (!this._isInitialized) {
      this._isInitializing = true;
    } else {
      this._isInitializing = false;
    }
  }

  public setInitializationObservable(initializationObservable: Observable<any>) {
    this.initializationState = new ObservableState(initializationObservable);
    const sub = this.initializationState.stateChange.subscribe((state: LoadingState) => {
      switch (state) {
        case LoadingState.InProgress:
          this._isInitializing = true;
          this._isInitialized = false;
          this._hasErrorInitializing = false;
          break;
        case LoadingState.Completed:
          this._isInitializing = false;
          this._isInitialized = true;
          this._hasErrorInitializing = false;
          break;
        case LoadingState.Error:
          this._isInitializing = false;
          this._isInitialized = false;
          this._hasErrorInitializing = true;
          break;
      }
    });
    this.subscriptionManager.addSubscription(sub);
    this.initializationState.watch();
  }

  public setSavingObservable(savingObservable: Observable<any>) {
    this.savingState = new ObservableState(savingObservable);
    const sub = this.savingState.stateChange.subscribe((state: LoadingState) => {
      switch (state) {
        case LoadingState.InProgress:
          this._isSaving = true;
          this._isSaved = false;
          this._hasErrorSaving = false;
          break;
        case LoadingState.Completed:
          this._isSaving = false;
          this._isSaved = true;
          this._hasErrorSaving = false;
          break;
        case LoadingState.Error:
          this._isSaving = false;
          this._isSaved = false;
          this._hasErrorSaving = true;
          break;
      }
    });
    this.subscriptionManager.addSubscription(sub);
    this.savingState.watch();
  }

  public setSaving(isSaving: boolean): void {
    this._isSaved = !isSaving;
    if (!this._isSaved) {
      this._isSaving = true;
    } else {
      this._isSaving = false;
    }
  }
}
